import "./styles/global.css"

import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { client } from './lib/apolloClient'
import { wrapPageElement as wrap } from "./wrapper";

export const wrapPageElement = wrap;

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
        <>{element}</>
    </ApolloProvider>
  )
}