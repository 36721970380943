import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Layout from "./layout";
import { extendTheme } from "@chakra-ui/react";

export const wrapPageElement = ({ element }) => {
  const theme = extendTheme({
    colors: {
      cdicyan: {
        50: "#eef4f6",
        100: "#ccdde5",
        200: "#abc6d4",
        300: "#89b0c2",
        400: "#6799b1",
        500: "#568ea9",
        600: "#4e8098",
        700: "#3d6376",
        800: "#2b4754",
        900: "#1a2b33",
      },
    },
    fonts: { heading: "Raleway", body: "Raleway" },
  });
  return (
    <ChakraProvider theme={theme}>
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
